:root {
  --T1: #000;
  --T2: #31373D;
  --T3: #5F6975;
  --T4: #9FA5AC;
  --T2-dark: rgba(255, 255, 255, 0.7);
  
  /* 品牌色 */
  --1: #9800F5;
  --BG: #EFF1F7;
  /* 主辅助色 */
  --2: #F15802;

  --BG: #EFF1F7;
  --image: #E8EBEE;
}
